























































































import { Component, Vue } from 'vue-property-decorator'
import {
  getAllArticleClassify,
  getArticleList,
  getrecommendArticle
} from '@/api/news'
@Component({
  components: {}
})
export default class News extends Vue {
  current = 1 //当前页数
  pageSize = 10 //每页条数
  total = 0 //总条数

  id = 0
  searchForm = { keywords: '' }
  ArticleClassify = [] //文章标签列表
  Articlelist = [] //文章列表
  recommend = []
  created() {
    getAllArticleClassify().then((res) => {
      this.ArticleClassify = res.data
      if (this.$route.query.id) {
        this.id = Number(this.$route.query.id)
      } else {
        this.id = res.data[0].id
      }

      this.getArticleList()
    })
    getrecommendArticle().then((res) => {
      this.recommend = res.data
    })
  }
  handleSearch() {
    
    this.$router.push({ path: '/search', query: { search: this.searchForm.keywords } })
  }
  getArticleList() {
    getArticleList({
      id: this.id,
      current: this.current,
      size: this.pageSize
    }).then((res) => {
      this.Articlelist = res.data.list
      this.total = res.data.total
    })
  }
  handleSelect(key: any) {
    this.id = key
    this.getArticleList()
  }
  Jump(id: any) {
    const routeUrl = this.$router.resolve({
      path: '/newsdetails',
      query: { id }
    })
    window.open(routeUrl.href, '_blank')
  }

  /**页数改变 */
  handleCurrentChange(val: any) {
    this.current = val
    this.getArticleList()
  }
  /**每页条数改变 */
  handleSizeChange(val: any) {
    this.pageSize = val
    this.getArticleList()
  }
}
